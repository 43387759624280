
import { defineComponent, onMounted, computed, ref } from "vue";
import { useStore } from "vuex";
import { Actions, Modules } from "@/store/enums/AdminStoreEnums";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { formatDateTime } from "@/core/helpers/date";
import ConfigurationEditModal from "@/views/admin/ConfigurationEditModal.vue";

export default defineComponent({
  name: "AdminConfigurations",
  components: { ConfigurationEditModal },
  setup() {
    const store = useStore();

    let isLoading = computed(() => {
      return store.getters[Modules.CONFIGURATION + "getIsLoading"];
    });
    let configurations = computed(() => {
      return store.getters[Modules.CONFIGURATION + "getConfigurations"];
    });
    let configuration = ref({});

    onMounted(() => {
      setCurrentPageTitle("Configurations");
    });

    fetchRecords();

    function refresh() {
      fetchRecords();
    }

    function fetchRecords() {
      store.dispatch(Modules.CONFIGURATION + Actions.FETCH_CONFIGURATIONS);
    }

    return {
      isLoading,
      configurations,
      configuration,
      refresh,
    };
  },
});
